import { defineStore } from 'pinia'
import type { INotification } from '@/types/shared'

export const useGlobalStore = defineStore('globalStore', () => {
  const notifications = ref<INotification[]>([])
  function removeNotification (notification: INotification) {
    notifications.value = notifications.value.filter(({ id }) => id !== notification.id)
  }
  return { notifications, removeNotification }
})
